<template>
  <div>
    <v-card class="mx-auto" :loading="fetchingData">
      <v-toolbar dark class="primary">
        <v-toolbar-title>{{ data.name }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-chip
          v-if="data.isApproved === '1'"
          class="ma-2"
          color="green"
          text-color="white"
          large
        >
          <v-icon left large> mdi-checkbox-marked-circle-outline </v-icon>
          Diese Bestellung wurde freigegeben
        </v-chip>

        <table class="default-table">
          <tr>
            <td>ID</td>
            <td>{{ data.id }}</td>
          </tr>

          <tr v-if="data.origin_id === '5'">
            <td>Name</td>
            <td>
              <Patcher
                :itemValue="data.name"
                itemName="name"
                itemLabel="name"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                :disabled="data.isApproved === '1' ? true : false"
                @emit="data.name = $event.value"
              ></Patcher>
            </td>
          </tr>

          <tr v-else>
            <td>Name</td>
            <td>{{ data.name }}</td>
          </tr>

          <tr>
            <td>Produkt-Typ</td>
            <td>{{ findProductTypeName(data.type_id) }}</td>
          </tr>

          <tr v-if="!planMonths_id">
            <td>Preis*</td>
            <td>
              <Patcher
                :itemValue="data.price"
                :previewValue="data.price + ' ' + data.currencyCode"
                itemName="price"
                itemLabel="Einzelpreis"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="input"
                inputType="number"
                :disabled="data.isApproved === '1' ? true : false"
                @emit="data.price = $event.value"
              ></Patcher>
            </td>
          </tr>

          <tr v-if="!planMonths_id">
            <td>Startdatum*</td>
            <td>
              <Patcher
                :itemValue="data.buyerStartDate"
                :previewValue="formatDate(data.buyerStartDate)"
                itemName="buyerStartDate"
                itemLabel="Startdatum"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="datepicker"
                inputType=""
                :disabled="data.isApproved === '1' ? true : false"
                @emit="data.buyerStartDate = $event.value"
              ></Patcher>
            </td>
          </tr>

          <tr v-else>
            <td>Preis</td>
            <td>Im JMP enthalten</td>
          </tr>

          <tr v-if="data.feePrice">
            <td>Zusatzgebühr</td>
            <td>{{ data.feePrice }} {{ data.currencyCode }}</td>
          </tr>

          <tr v-if="data.billingPeriod">
            <td>Abrechnungszeitraum</td>
            <td>{{ this.$t("phrases." + data.billingPeriod) }}</td>
          </tr>
          <tr v-if="data.runtimeCount">
            <td>Laufzeit</td>
            <td>
              {{ data.runtimeCount }}
              <span v-if="data.runtimePeriod">{{
                this.$t("phrases." + data.runtimePeriod)
              }}</span>
            </td>
          </tr>
          <tr v-if="data.renewalAutomatic">
            <td>Automatische Verlängerung</td>
            <td>
              <span v-if="data.renewalAutomatic === '1'">Ja</span>
              <span v-else>Nein</span>
            </td>
          </tr>
          <tr v-if="data.cancellationCount">
            <td>Kündigung</td>
            <td>
              {{ data.cancellationCount }}
              <span v-if="data.runtimePeriod">{{
                this.$t("phrases." + data.runtimePeriod)
              }}</span>
            </td>
          </tr>
        </table>

        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="10">
                <v-autocomplete
                  v-model="selectedCompany"
                  :items="companiesList"
                  @change="updateData('companies_id')"
                  :disabled="data.isApproved === '1' ? true : false"
                  :loading="updatingData"
                  item-text="name"
                  item-value="id"
                  outlined
                  label="Bitte wähle eine Firma aus*"
                ></v-autocomplete>
              </v-col>

              <v-col cols="2">
                <CreateCompany
                  :buttonFloating="false"
                  :buttonFab="true"
                  :disabled="data.isApproved === '1' ? true : false"
                  @result="createCompanyKey += 1"
                  :key="createCompanyKey"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <template v-if="data.isApproved !== '1'">
          <v-row>
            <v-col cols="12">
              <h2>Bestellung freigeben</h2>
              <p class="caption my-2">
                Wird eine Bestellung freigegeben, wird automatisch eine
                Bestätigung des Auftrags per E-Mail an den Kunden versendet.
              </p>
              <Patcher
                :itemValue="data.manualAgreement"
                itemName="manualAgreement"
                itemLabel="Zusätzliche Vereinbarungen"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="textarea-md"
                inputType=""
                :disabled="
                  !selectedCompany || !data.buyerStartDate ? true : false
                "
                hint="Zusätzliche Vereinbarungen werden dem Kunden in der
                Auftragsbestätigung E-Mail angezeigt. Sie bieten also Platz für
                etwaige Vereinbarungen, Sonderwünsche etc."
                @emit="data.manualAgreement = $event.value"
              ></Patcher>

              <h3 class="mt-4">Sonstiges</h3>
              <Patcher
                v-if="!planMonths_id"
                :itemValue="data.contextJmp"
                :previewValue="data.contextJmp"
                itemName="contextJmp"
                itemLabel="Bestellung in einem JMP - Kontext"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="input"
                inputType="checkbox"
                :disabled="data.isApproved === '1' ? true : false"
                @emit="data.jmpContext = $event.value"
              ></Patcher>

              <Patcher
                v-if="!planMonths_id"
                :itemValue="data.sendNoSetupMail"
                :previewValue="data.sendNoSetupMail"
                itemName="sendNoSetupMail"
                itemLabel="KEIN Versand der Bestellbestätigung"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="input"
                inputType="checkbox"
                :disabled="data.isApproved === '1' ? true : false"
                @emit="data.sendNoSetupMail = $event.value"
              ></Patcher>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="white--text ma-1"
                :loading="fetchingData"
                :disabled="
                  !selectedCompany || data.buyerStartDate === null
                    ? true
                    : false
                "
                color="green"
                @click="autoSetupPrompt()"
              >
                <v-icon left> mdi-check </v-icon>
                Freigeben
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <h3 class="mt-4">Bestellung in einem JMP-Kontext</h3>
          <Patcher
            v-if="!planMonths_id"
            :itemValue="data.contextJmp"
            :previewValue="data.contextJmp"
            itemName="contextJmp"
            itemLabel="JMP-Kontext"
            :hideLabel="false"
            :route="patchRoute"
            inputElement="input"
            inputType="checkbox"
            @emit="data.jmpContext = $event.value"
          ></Patcher>

          <h3 v-if="data.manualAgreement" class="mb-2">
            Zusätzliche Vereinbarungen:
          </h3>
          <vue-markdown
            v-if="data.manualAgreement"
            :source="data.manualAgreement"
          ></vue-markdown>
        </template>

        <Notes
          :route="`/orders/${orderId}/products/${data.id}/notes`"
          :key="notesKey"
          class="my-4"
        />

        <v-btn
          class="white--text ma-1"
          :loading="fetchingData"
          color="red"
          x-small
          :disabled="data.isApproved === '1' ? true : false"
          @click="cancelOrder()"
        >
          <v-icon left small> mdi-alert </v-icon>
          Bestellung stornieren
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import Notes from "@/components/global/notes/Notes.vue";
import CreateCompany from "@/components/companies/CreateCompany.vue";
import Patcher from "@/components/ui/Patcher.vue";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
export default {
  name: "Product",
  components: {
    Notes,
    CreateCompany,
    Patcher,
    VueMarkdown,
  },
  data() {
    return {
      createCompanyKey: 0,
      createCompanyDialog: false,
      fetchingData: false,
      updatingData: false,
      productTypes: [],
      companiesList: [],
      selectedCompany: this.data.companies_id,
      componentKey: 0,
      notesKey: 0,
      patchRoute: `/orders/${this.orderId}/products/${this.data.id}`,
    };
  },
  props: ["orderId", "data", "planMonths_id"],

  created: function () {
    this.getData();
  },

  methods: {
    async autoSetupPrompt() {
      const userChoice = await this.$dialog.confirm({
        text: "Soll diese Bestellung tatsächlich freigegeben - und automatisch eingerichtet werden?",
        title: "Automatische Einrichtung",
        waitForResult: true,
      });

      if (userChoice) {
        // Die neue Route lautet POST /v1/orders/{orderID}/createCampaign Also z.B. POST https://.../v1/orders/660/createCampaign
        // Neue Route POST /orders/{id}/products/{productId}/approve (da produktbasiert)
        this.updatingData = true;
        let response = await this.postRequest(
          `orders/${this.orderId}/products/${this.data.id}/approve`
        );
        this.updatingData = false;
        if (response.status === 200) {
          this.data.isApproved = "1";
          this.notesKey += 1; // re-Render Notes
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Bestellung wurde freigegeben und eine Kampagne eingerichtet.";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async cancelOrder() {
      const userChoice = await this.$dialog.confirm({
        text: "Soll diese Bestellung tatsächlich storniert werden?",
        title: "Bist du dir sicher?",
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        alert("In Kürze verfügbar");
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getCompanies() {
      this.fetchingData = true;
      let response = await this.getRequest("companies");
      this.fetchingData = false;
      if (response.status === 200) {
        this.companiesList = response.data.data;
      }
    },

    async updateData(field) {
      const params = new URLSearchParams();

      if (field === "companies_id") {
        params.append("companies_id", this.selectedCompany);
      }

      this.updatingData = true;
      let response = await this.patchRequest(
        `/orders/${this.orderId}/products/${this.data.id}`,
        params
      );
      this.updatingData = false;

      if (response.status === 200) {
        this.notesKey += 1; // re-Render Notes
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Eine Firma wurde zugewiesen";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("products/types"); // get productTypes
      this.getCompanies();
      if (response.status === 200) {
        this.productTypes = response.data.data;
      }
    },

    findProductTypeName(id) {
      let name = "";
      this.productTypes.forEach((item) => {
        if (id === item.id) {
          name = item.name;
        }
      });
      return name;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
