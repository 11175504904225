<template>
  <v-card class="mx-auto">
    <v-toolbar dark class="grey darken-3">
      <v-toolbar-title>Kauf</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <table class="default-table">
        <tr>
          <td>Kaufdatum</td>
          <td>
            {{ formatDateTime(data.created) }}
          </td>
        </tr>
        <tr>
          <td>Quelle</td>
          <td>
            <v-chip v-if="planMonths_id" small color="teal" class="white--text">
              JMP
            </v-chip>
            <v-chip v-else small color="blue-grey" class="white--text">
              Direktkauf
            </v-chip>
          </td>
        </tr>
        <template v-if="!planMonths_id">
          <tr>
            <td>Bezahlmethode</td>
            <td>{{ data.paymentMethodName }}</td>
          </tr>
          <tr>
            <td>Währung</td>
            <td>{{ data.currencyCode }}</td>
          </tr>
          <template v-if="data.included.payment">
            <tr v-if="data.included.payment.ibanGivenLater === '1'">
              <td>IBAN</td>
              <td>Wird nachgereicht</td>
            </tr>
            <tr v-else-if="data.included.payment.ibanIsAvailable === '1'">
              <td>IBAN</td>
              <td>Liegt bereits vor</td>
            </tr>
            <tr v-else-if="data.included.payment.iban">
              <td>IBAN</td>
              <td>{{ data.included.payment.iban }}</td>
            </tr>
          </template>

          <tr>
            <td>Gutscheincode</td>
            <td v-if="data.discountCode">
              {{ data.discountCode }} {{ data.discountCodeText }}
            </td>
            <td v-else>-</td>
          </tr>
        </template>
        <tr>
          <td>Notiz</td>
          <td v-if="data.note">{{ data.note }}</td>
          <td v-else>-</td>
        </tr>
      </table>

      <BuyerCard
        :orderId="data.id"
        :data="data.included.buyer"
        :products="data.included.products[0]"
      ></BuyerCard>
    </v-card-text>
  </v-card>
</template>

<script>
import helpers from "@/mixins/helpers";
import BuyerCard from "@/components/orders/BuyerCard.vue";
export default {
  name: "OrderCard",
  props: ["orderId", "data", "planMonths_id"],
  components: {
    BuyerCard,
  },
  mixins: [helpers],
};
</script>
