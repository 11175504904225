<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark class="primary">
        <v-toolbar-title>
          <span v-if="!noteId" class="headline">Eine Notiz erstellen</span>
          <span v-else class="headline">Eine Notiz bearbeiten</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          dark
          fab
          small
          text
          @click="$emit('emit', { action: 'closeModal' })"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="!fetchingData && !fetchingCatData" class="py-6">
        <v-form id="form" ref="form" v-model="valid" lazy-validation>
          <!-- <v-select
            solo
            :items="noteCategories"
            label="Kategorie"
            item-text="title"
            item-value="id"
            v-model="noteCategoryId"
            :rules="noteCategoryRules"
            required
          ></v-select> -->
          <v-select
            v-model="noteCategoryId"
            :items="noteCategories"
            attach
            chips
            label="Kategorien"
            item-text="title"
            item-value="id"
            item-color="color"
            :rules="noteCategoryRules"
            required
            multiple
          ></v-select>

          <v-textarea
            solo
            name="note"
            v-model="note"
            label="Was möchtest Du sagen?"
            :rules="noteRules"
            required
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!noteId"
          :disabled="!valid || note.length < 1"
          color="green"
          class="white--text"
          @click="postData"
        >
          Notiz Speichern
        </v-btn>
        <v-btn
          v-else
          :disabled="!valid"
          color="green"
          class="white--text"
          @click="updateData"
          >Notiz Aktualisieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";

export default {
  data() {
    return {
      valid: false,
      editProcess: false,
      note: "",
      noteCategories: null,
      dialog: true,
      noteRules: [(v) => !!v || "Ein Text ist Pflicht"],
      noteCategoryRules: [(v) => !!v || "Eine Kategorie ist Pflicht"],
      noteCategoryId: null,
      fetchingData: false,
      fetchingCatData: false,
      configs: {
        status: false,
        spellChecker: false,
        sanitize: true,
        toolbar: [
          "bold",
          "italic",
          "heading-1",
          "heading-2",
          "heading-3",
          "unordered-list",
          "ordered-list",
          "link",
          "preview",
        ],
      },
    };
  },

  computed: {},

  props: ["noteId", "productId", "route"],

  components: {},

  created: function () {
    if (this.noteId) {
      this.getData(); // call it immediatly
    }
    this.getCategoriesData();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    async getCategoriesData() {
      this.fetchingCatData = true;
      let categoriesResponse = await this.getRequest(`notesCategories`);
      this.noteCategories = categoriesResponse.data.data;
      // remove item with id 1
      this.noteCategories = this.noteCategories.filter(
        (item) => item.id !== "1"
      );
      this.fetchingCatData = false;
    },

    async getData() {
      /* prop id is there -> fetch the note */
      this.fetchingData = true;
      let response = await this.getRequest(`${this.route}/${this.noteId}`);
      this.fetchingData = false;
      this.note = response.data.data.note;

      const commaSeparatedString = response.data.data.category_ids;

      // convert comma separated string to array
      this.noteCategoryId = commaSeparatedString.split(",");
    },

    async updateData() {
      // convert this.noteCategoryId array to comma separated string

      const commaSeparatedString = this.noteCategoryId.join(",");

      const params = new URLSearchParams();
      params.append("note", this.note);
      params.append("category_ids", commaSeparatedString);

      this.fetchingData = true;
      let response = await this.patchRequest(
        `${this.route}/${this.noteId}`,
        params
      );
      this.fetchingData = false;
      let theNote = response.data.data; // the new post
      this.$emit("emit", { action: "updated", theNote: theNote });
    },

    async postData() {
      let formData = new FormData();
      formData.append("note", this.note);
      formData.append("category_ids", this.noteCategoryId);

      this.fetchingData = true;
      let response = await this.postRequest(`${this.route}`, "", formData);
      this.fetchingData = false;
      let theNote = response.data.data;
      this.$emit("emit", { action: "created", theNote: theNote });
    },
  },

  name: "EditNote",
  mixins: [apiRequest],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
