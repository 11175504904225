<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card v-if="!fetchingData">
        <v-toolbar dark class="primary">
          <v-toolbar-title>
            <span class="headline">Einen Firmendatensatz anlegen</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn dark fab small text @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pt-4">
          <v-form id="form" ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  :items="businessCategorys"
                  label="Branche"
                  item-text="name"
                  item-value="value"
                  v-model="businessCategory"
                  :rules="requiredRules"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  label="Name"
                  v-model="name"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  label="Firmenbezeichnung"
                  v-model="legalName"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  :items="legalForms"
                  label="Rechtsform"
                  item-text="name"
                  item-value="value"
                  v-model="legalForm"
                  :rules="requiredRules"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  label="Straße"
                  v-model="street"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  label="Hausnummer"
                  v-model="streetNumber"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  label="PLZ"
                  v-model="zipCode"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="7">
                <v-text-field
                  label="Stadt"
                  v-model="town"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  :items="countries"
                  label="Land"
                  item-text="name"
                  item-value="value"
                  v-model="country"
                  :rules="requiredRules"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="E-Mail"
                  v-model="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Telefon"
                  v-model="phone"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            color="green"
            class="white--text"
            @click="postData"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="buttonFloating"
          fixed
          fab
          bottom
          right
          color="primary"
          @click="dialog = true"
          v-bind="attrs"
          v-on="on"
          ><v-icon>mdi-plus</v-icon></v-btn
        >

        <v-btn
          v-else
          fab
          small
          elevation="0"
          color="primary"
          @click="dialog = true"
          v-bind="attrs"
          v-on="on"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </template>
      <span>Kunde anlegen</span>
    </v-tooltip>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";

export default {
  name: "CreateCompany",
  data() {
    return {
      dialog: false,
      valid: false,
      editProcess: false,
      note: "",
      requiredRules: [(v) => !!v || "Pflichtfeld"],
      emailRules: [
        (v) => !!v || "Pflichtfeld",
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      configs: {
        status: false,
        spellChecker: false,
        sanitize: true,
        toolbar: [
          "bold",
          "italic",
          "heading-1",
          "heading-2",
          "heading-3",
          "unordered-list",
          "ordered-list",
          "link",
          "preview",
        ],
      },
      legalForms: [
        { name: "GmbH", value: "gmbh" },
        { name: "GbR", value: "gbr" },
      ],
      businessCategorys: [],
      countries: [],
      businessCategory: "",
      name: "",
      legalName: "",
      legalForm: "",
      street: "",
      streetNumber: "",
      zipCode: "",
      town: "",
      country: "",
      email: "",
      phone: "",
      fax: "",
      url: "",
    };
  },

  props: ["buttonFloating", "buttonFab", "disabled"],

  components: {},

  created: function () {
    this.getData();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    async getData() {
      this.fetchingData = true;

      let responsebusinessCategorys = await this.getRequest(
        "products/branches"
      );
      if (responsebusinessCategorys.status === 200) {
        this.businessCategorys = responsebusinessCategorys.data.data;
      }

      let responseCountries = await this.getRequest(
        "countries?filter[mostWanted]"
      );
      if (responseCountries.status === 200) {
        this.countries = responseCountries.data.data;
      }

      let responseLegalForms = await this.getRequest("companiesLegalForms ");
      if (responseLegalForms.status === 200) {
        this.legalForms = responseLegalForms.data.data;
      }

      this.fetchingData = false;
    },

    async postData() {
      let formData = new FormData();
      formData.append("businessCategory", this.businessCategory);
      formData.append("name", this.name);
      formData.append("legalName", this.legalName);
      formData.append("legalForm", this.legalForm);
      formData.append("street", this.street);
      formData.append("streetNumber", this.streetNumber);
      formData.append("zipCode", this.zipCode);
      formData.append("town", this.town);
      formData.append("country", this.country);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("fax", this.fax);
      formData.append("url", this.url);

      this.fetchingData = true;
      let response = await this.postRequest(`companies`, "", formData);
      this.fetchingData = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Ein neuer Datensatz wurde angelegt.";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.dialog = false;
        this.$emit("created", true);
      }
    },
  },

  mixins: [apiRequest],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
