<template>
  <v-container fluid class="pa-0">
    <v-card flat>
      <v-toolbar dark class="primary darken-2" elevation="0">
        <v-toolbar-title>
          <v-icon color="white"> mdi-note-multiple-outline </v-icon>
          Notizen</v-toolbar-title
        >
        <v-spacer />
        <v-btn dark fab small text @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="items.length > 0">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suchen"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-card-text>
            <v-data-table
              item-class="caption"
              :headers="headers"
              :items="items"
              :search="search"
              :loading="fetchingData"
            >
              <template v-slot:[`item.note`]="{ item }">
                <div
                  style="
                    max-height: 150px;
                    max-width: 400px;
                    overflow-y: auto;
                    margin: 1rem 0;
                    padding: 0.5rem;
                  "
                >
                  {{ item.note }}
                </div>
              </template>

              <template v-slot:[`item.categories`]="{ item }">
                <v-chip
                  v-for="category in item.categories"
                  :key="category.id"
                  small
                  :color="category.color"
                  text-color="white"
                  class="my-1 mx-1"
                >
                  {{ category.title }}
                </v-chip>
              </template>

              <template v-slot:[`item.actions`]="{ item, index }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :disabled="isDisabled(item.categories, item.users_id)"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-btn
                        text
                        color="green"
                        :disabled="isDisabled(item.categories, item.users_id)"
                        @click="editNote(item.id, index)"
                      >
                        Bearbeiten
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn
                        text
                        color="error"
                        :disabled="isDisabled(item.categories, item.users_id)"
                        @click="deleteNote(item.id, index)"
                      >
                        Löschen
                      </v-btn>
                    </v-list-item>

                    <v-list-item v-if="item.updated" class="caption pa-2">
                      <v-chip small class="ma-2">
                        Aktualisiert am {{ formatDateTime(item.updated) }}
                      </v-chip>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-else> Noch keine Notizen vorhanden </v-card-text>
    </v-card>

    <div v-if="dialog">
      <EditNote
        @emit="editDialogAction"
        :noteId="noteId"
        :route="route"
        :key="componentKey"
      ></EditNote>
    </div>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import EditNote from "@/components/global/notes/EditNote";

export default {
  name: "Notes",

  components: {
    EditNote,
  },

  props: ["route"],

  data() {
    return {
      search: "",
      headers: [
        { text: "Kategorie", value: "categories" },
        {
          text: "Datum",
          align: "start",
          value: "created",
          sortable: false,
        },
        { text: "User", value: "userFullname" },
        { text: "Notiz", value: "note", sortable: false },
        { text: "", value: "actions" },
      ],
      items: [],
      dialog: false,
      lazy: true,
      valid: true,
      singleNote: "",
      note: "",
      noteId: null,
      editProcess: "",
      fetchingData: true,
      index: "",
      id: null,
      componentKey: 0,
      adminId: this.$store.getters["auth/user"].id,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    isDisabled(categories, users_id) {
      // loop threw categories array
      let systemNote = false;

      if (categories) {
        categories.forEach((category) => {
          if (category.id === "1") {
            systemNote = true;
          }
        });
      }

      if (systemNote || this.adminId !== users_id) {
        return true;
      }
    },

    editDialogAction(value) {
      /* emited actions from the modal component (EditDialog) */

      if (value.action === "closeModal") {
        this.dialog = false;
      } else if (value.action === "updated") {
        this.dialog = false;
        let theNote = value.theNote; // emited
        theNote.created = this.formatDateTime(theNote.created);
        theNote.userFullname = theNote.firstname + " " + theNote.lastname;
        this.items.splice(this.index, 1, theNote); // replace object in array

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Die Notiz wurde aktualisiert";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else if (value.action === "created") {
        this.dialog = false;
        let theNote = value.theNote; // emited
        theNote.created = this.formatDateTime(theNote.created);
        theNote.userFullname = theNote.firstname + " " + theNote.lastname;

        this.items.unshift(theNote); // add to array
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Die Notiz wurde hinzugefügt";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    editbuttonAction(value) {
      /* emited actions from the Buttons component (EditButtons) */

      if (value.action === "editItem") {
        this.noteId = value.itemId; // setting id of current post in array
        this.index = value.itemIndex; // setting index of current post in array
        this.dialog = true; // open dialog
      } else if (value.action === "deleteItem") {
        this.id = value.itemId; // setting id of current post in array
        this.index = value.itemIndex; // setting index of current post in array
        this.deleteItem();
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(this.route);
      this.fetchingData = false;

      // sortiere nach Datum absteigend
      response.data.data.sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
      });

      response.data.data.forEach((item) => {
        item.created = this.formatDateTime(item.created);

        if (item.firstname) {
          item.userFullname = item.firstname + " " + item.lastname;
        } else if (item.userFirstname) {
          item.userFullname = item.userFirstname + " " + item.userLastname;
        } else {
          item.userFullname = "System";
        }
      });

      this.items = response.data.data;
    },

    editNote(id, index) {
      this.noteId = id; // setting id of current post in array
      this.index = index; // setting index of current post in array
      this.dialog = true; // open dialog
    },

    deleteNote(id, index) {
      this.deletePrompt(id, index);
    },

    async deleteItemAction(id, index) {
      this.fetchingData = true;
      let response = await this.deleteRequest(`${this.route}/${id}`);
      this.fetchingData = false;

      if (response) {
        this.items.splice(index, 1);
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Die Notiz wurde gelöscht";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async deletePrompt(id, index) {
      const userChoice = await this.$dialog.confirm({
        text: "Soll diese Notiz wirklich gelöscht werden?",
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        this.deleteItemAction(id, index);
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    deleteItem(id, item, i) {
      this.deletePrompt(id, item, i);
    },

    addItem() {
      this.noteId = ""; // reset, when id = '' a new note gets created
      this.componentKey += 1;
      this.dialog = true;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>

<style>
.v-data-table tbody td {
  font-size: 13px !important;
}
</style>
