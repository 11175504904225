<template>
  <div>
    <div v-if="data && data.email">
      <h2>Käufer</h2>
      <table class="default-table" style="width: auto">
        <tr>
          <td>Käufer</td>
          <td>
            <span v-if="data.gender === 'female'">Frau</span>
            <span v-else-if="data.gender === 'male'">Herr</span>
            <span v-else>Divers</span>
            {{ data.firstName }}
            {{ data.lastName }}
          </td>
        </tr>
        <tr>
          <td>Firma</td>
          <td>
            {{ data.companyName }}
          </td>
        </tr>
        <tr>
          <td>E-Mail</td>
          <td>
            {{ data.email }}
          </td>
        </tr>
        <tr>
          <td>Telefon</td>
          <td>
            {{ data.phone }}
          </td>
        </tr>
        <tr>
          <td>Adresse</td>
          <td>
            {{ data.street }}
            {{ data.streetnumber }}<br />
            {{ data.zip }}
            {{ data.city }},
            {{ data.countryCode }}
          </td>
        </tr>
        <tr>
          <td>Web</td>
          <td>
            {{ data.url }}
          </td>
        </tr>
      </table>
    </div>

    <!-- <div v-else>
      <h2 class="mb-4">Käufer</h2>
      <span v-if="products.isJmp === '1'">
        Die Bestellung wurde im Zuge eines
        <v-chip small color="teal" class="white--text"> JMP </v-chip>
        ausgelöst.
      </span>

      <span v-else>
        Diese Bestellung wurde von einem FACEFORCE-Admin manuell angelegt.
        Schaue dazu bitte in die Notizen.
      </span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "BuyerCard",
  props: ["order", "data", "products"],
};
</script>
