<template>
  <div>
    <PageHeader
      :pageTitle="'Bestellung: ' + orderId"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row v-if="data && data.included">
      <v-col cols="12" lg="6">
        <v-row>
          <v-col
            cols="12"
            v-for="item in data.included.products"
            :key="item.id"
          >
            <Product
              :orderId="orderId"
              :data="item"
              :planMonths_id="data.planMonths_id"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" lg="6">
        <OrderCard
          :orderId="orderId"
          :data="data"
          :planMonths_id="data.planMonths_id"
        ></OrderCard>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12"> Loading </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
/* import BuyerCard from "@/components/orders/BuyerCard.vue"; */
import OrderCard from "@/components/orders/OrderCard.vue";
import Product from "@/components/orders/Product.vue";

export default {
  name: "Order",

  components: {
    PageHeader,
    /* BuyerCard, */
    OrderCard,

    Product,
  },

  data() {
    return {
      pageTitle: "Bestellung",
      pageDescription:
        "Hier findest Du alle Infos zur vorliegenden Bestellung.",
      orderId: this.$route.params.id,
      data: [],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      // GET PRODUCTS
      const result = await this.getRequest(
        "orders/" + this.orderId + "?include=products,buyer,payment"
      );

      this.data = result.data.data;
    },
  },

  mixins: [apiRequest],
};
</script>
